<template>
    <div class="approval-participants">
        <div class="approval-participants__title">
            Участники согласования
        </div>

        <div class="approval-participants__content">
            <div class="approval-participants__tag">Новый отклик</div>

            <div class="approval-participants__content-wrapper">
                <div v-for="(emp, index) in emps" :key="index" v-show="emp.name" class="approval-participants__emp">
                    <div class="approval-participants__emp-group">
                        <div class="approval-participants__emp-name">{{ emp.name }}</div>
                        <div v-if="emp.role" class="approval-participants__emp-role">({{ emp.role }})</div>
                    </div>

                    <div v-if="emp.type" class="approval-participants__emp-text">{{ emp.type }}</div>
                </div>
            </div>

            <div class="approval-participants__tag">Закрытие вакансии</div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";

const props = defineProps({
    fields: Object
})

const emps = ref([])

const getEmployeeInfo = (id) => {
    const employee = props.fields.employees.find(emp => emp.id === id)

    return employee ? employee : {}
}

const createEmps = () => {
    props.fields?.approval_route?.step_1?.forEach(item => {
        emps.value.push({
            name: getEmployeeInfo(item.employee_id)?.full_name,
            role: getEmployeeInfo(item.employee_id)?.job_title,
            type: '',
            typeId: 'emp'
        })
    })
    emps.value.push({
        name: props.fields?.vacancy?.responsible?.extended_form?.employee_full_name,
        role: props.fields?.vacancy?.responsible?.extended_form?.employee_job_title,
        type: 'Проверка документов',
        typeId: 'step_1'
    })

    props.fields?.approval_route?.step_2?.forEach(item => {
        emps.value.push({
            name: getEmployeeInfo(item.employee_id)?.full_name,
            role: getEmployeeInfo(item.employee_id)?.job_title,
            type: '',
            typeId: 'emp'
        })
    })
    emps.value.push({
        name: props.fields?.vacancy?.responsible?.medical?.employee_full_name,
        role: props.fields?.vacancy?.responsible?.medical?.employee_job_title,
        type: 'Медецинская проверка',
        typeId: 'step_2'
    })

    props.fields?.approval_route?.step_3?.forEach(item => {
        emps.value.push({
            name: getEmployeeInfo(item.employee_id)?.full_name,
            role: getEmployeeInfo(item.employee_id)?.job_title,
            type: '',
            typeId: 'emp'
        })
    })
    emps.value.push({
        name: props.fields?.vacancy?.responsible?.buying_tickets?.employee_full_name,
        role: props.fields?.vacancy?.responsible?.buying_tickets?.employee_job_title,
        type: 'Покупка билетов',
        typeId: 'step_3'
    })
}

onMounted(() => {
    createEmps()
})
</script>

<style scoped lang="scss">
.approval-participants {
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 1.92017px 1.92017px 7.68069px 0 rgba(0, 0, 0, 0.04);

    &__title {
        font-size: 20px;
        font-weight: 500;
    }

    &__content {
        width: 100%;
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-top: 1px solid $light-gray-1;
    }

    &__tag {
        padding: 4px 8px;
        border-radius: 6px;
        background-color: $light;
        color: $gray;
        font-size: 12px;
        font-weight: 400;
    }

    &__content-wrapper {
        width: 100%;
        border-left: 2px solid $light-gray-1;
        margin-left: 24px;
        padding-top: 24px;
        padding-left: 24px;
        padding-bottom: 32px;
    }

    &__emp {
        padding: 0 24px 0 16px;
        width: calc(100% - 24px);
        height: 48px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $light;

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__emp-group {
        display: flex;
        align-items: center;
    }

    &__emp-name {
        font-size: 14px;
        font-weight: 400;
    }

    &__emp-role {
        margin-left: 6px;
        color: $gray;
        font-size: 14px;
        font-weight: 400;
    }

    &__emp-text {
        font-size: 12px;
        font-weight: 400;
    }
}
</style>
