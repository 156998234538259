<template>
    <div class="route">
        <router-link :to="`/vacancies/${route.params.id}`" class="route__back">
            <img src="@/assets/images/icons/back-arrow.svg" alt="back">
            Инженер-конструктор 3 разряда
        </router-link>

        <h1 class="route__title">Маршрут согласования</h1>
        <p class="route__text">
            Добавьте согласовантов кандидата между этапами прохождения откликов по бизнес-процессу, который установлен
            у вас в компании
        </p>

        <div class="container">
            <ApprovalParticipants v-if="fieldsVacancies.vacancy" :fields="fieldsVacancies" class="route__block"/>
        </div>
    </div>
</template>

<script setup>
import {onActivated, onMounted, ref} from "vue";
import {useRouter, useRoute} from "vue-router";
import vacancies from "@/api/vacancies";
import ApprovalParticipants from "../../components/VacancyDetailCard/ApprovalParticipants"

const route = useRoute();
const router = useRouter();

const fieldsVacancies = ref({});
const vacancyId = ref(route.params.id);

const getFieldsVacancies = async () => {
    try {
        fieldsVacancies.value = await vacancies.getFieldsVacancies({
            vacancy_id: vacancyId.value,
        });

    } catch (error) {
        console.log(error);
    }
};

onMounted(async () => {
    await getFieldsVacancies();
});
</script>

<style scoped lang="scss">
.route {
    &__back {
        margin-top: 12px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: $gray;
        text-decoration: none;

        img {
            width: 20px;
            margin-right: 4px;
        }
    }

    &__title {
        margin-top: 8px;
        font-size: 28px;
        font-weight: 700;
    }

    &__text {
        max-width: 544px;
        margin-top: 8px;
        color: $gray;
        font-size: 14px;
        font-weight: 400;
    }

    &__block {
        margin-top: 24px;
    }
}
</style>
